import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import styled, { keyframes } from "styled-components";
import backroundPath from "../img/logo.svg";
import qoutePath from "../img/qoute.svg";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import BackroundFill from "../assets/bgfill.svg";
import { withPrefix } from "gatsby";
import SvgBackground from "../components/SvgBackground";
import { Home, HomeContent } from "./index-page";
import AOS from "aos";
import "aos/dist/aos.css";

const animation = keyframes` 
 0%,
 100% {
   -webkit-transform: translateX(0%);
           transform: translateX(0%);
   -webkit-transform-origin: 50% 50%;
           transform-origin: 50% 50%;

 }
 15% {
   -webkit-transform: translateX(-30px) rotate(-6deg);
           transform: translateX(-30px) rotate(-6deg);
 }
 30% {
   -webkit-transform: translateX(15px) rotate(6deg);
           transform: translateX(15px) rotate(6deg);
 }
 45% {
   -webkit-transform: translateX(-15px) rotate(-3.6deg);
           transform: translateX(-15px) rotate(-3.6deg);
 }
 60% {
   -webkit-transform: translateX(9px) rotate(2.4deg);
           transform: translateX(9px) rotate(2.4deg);
 }
 75% {
   -webkit-transform: translateX(-6px) rotate(-1.2deg);
           transform: translateX(-6px) rotate(-1.2deg);
 }
`;

const AboutText = styled.div`
  display: flex;
  color: white;
  width: 50%;
  /* flex: 1; */
  text-align: left;

  font-size: 14px;
  text-transform: uppercase;
  font-family: "Nexa";
  :hover {
    blockquote {
      animation: ${animation} 0.8s both;
    }
  }
  blockquote {
    display: inline-flex;
    font-family: "Nexa Bold";
    justify-content: center;
    margin: 0;
    width: 100%;
    p {
      width: 50%;
      padding: 11px;

      text-align: center;
    }
    :before {
      display: block;
      content: " ";
      background: url(${qoutePath});
      background-size: 28px 28px;
      height: 28px;
      width: 28px;
      transform: rotate(-180deg);
    }
    :after {
      display: flex;
      place-self: flex-end;
      content: " ";
      background: url(${qoutePath});
      background-size: 28px 28px;
      height: 28px;
      width: 28px;
    }
  }

  @media (max-width: 968px) {
    width: 100%;
  }
`;
const AboutImagesWrapper = styled.div`
  display: block;
  /* position: relative; */
  /* padding-top: 4rem; */
  position: absolute;
`;
const AboutImages = styled.div`
  display: flex;
  /* flex: 2; */
  justify-content: center;
  height: 650px;
  width: 100%;
  position: relative;
  place-self: flex-start;
  @media (max-width: 968px) {
    width: 80%;
    height: 335px;
  }

  & ${AboutImagesWrapper}:nth-child(2) {
    /* background: yellow; */
    /* width: 250px; */
    width: 50%;

    /* position: absolute; */
    /* left: 265px;
    top: -96px; */
    /* left: 265px; */

    left: 70%;
    top: 3%;
  }
  & ${AboutImagesWrapper}:nth-child(1) {
    /* background: pink; */
    /* width: 500px; */
    width: 100%;
    /* top: 10%; */
    top: 5%;

    /* position: absolute; */
  }
  & ${AboutImagesWrapper}:nth-child(3) {
    /* background: blue; */
    /* width: 250px; */
    width: 50%;

    /* position: absolute; */
    /* top: -33px;
    right: 44px; */
    /* top: -90px; */

    top: -8%;

    right: 70px;
    @media (max-width: 968px) {
      top: -4%;
      right: 15%;
    }
  }
  & ${AboutImagesWrapper}:nth-child(4) {
    /* background: orange; */
    /* width: 200px; */
    width: 40%;

    /* position: absolute; */
    /* right: -50px;
    top: 165px; */
    left: 98%;
    top: 22%;
    @media (max-width: 968px) {
      top: 17%;
    }
  }
  & ${AboutImagesWrapper}:nth-child(5) {
    /* background: red; */

    /* width: 300px; */
    width: 60%;

    /* position: absolute; */
    /* top: 315px;
    right: -44px; */

    top: 40%;
    left: 68%;
    @media (max-width: 968px) {
      top: 25%;
    }
  }
`;
const AboutUsContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3rem;
  place-items: center;
  @media (max-width: 968px) {
    flex-direction: column-reverse;
    /* padding: 0.2rem; */
  }
`;
export const AllWraped = styled.div`
  width: 400px;
  position: relative;

  @media (max-width: 968px) {
    width: 200px;
  }
`;
export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  images,
}) => {
  const PageContent = contentComponent || Content;
  React.useEffect(() => {
    AOS.init();
  });
  return (
    // <section className="section section--gradient">
    //   <div className="container">
    //     <div className="columns">
    //       <div className="column is-10 is-offset-1">
    //         <div className="section">
    //           <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
    //             {title}
    //           </h2>
    <>
      <AboutText>
        {" "}
        <PageContent className="content" content={content} />
      </AboutText>
      <AboutImages
        data-aos="zoom-in"
        data-aos-offset="80"
        data-aos-delay="0"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="false"
        data-aos-anchor-placement="top-center"
      >
        <AllWraped>
          <AboutImagesWrapper>
            <SvgBackground
              link={
                images.image2.image.extension == "svg"
                  ? images.image2.image.publicURL
                  : images.image2.image.childImageSharp?.fluid.src
              }
              id="img2"
            />
          </AboutImagesWrapper>
          <AboutImagesWrapper>
            <SvgBackground
              link={
                images.image1.image.extension == "svg"
                  ? images.image1.image.publicURL
                  : images.image1.image.childImageSharp?.fluid.src
              }
              id="img1"
            />
          </AboutImagesWrapper>

          <AboutImagesWrapper>
            <SvgBackground
              link={
                images.image3.image.extension == "svg"
                  ? images.image3.image.publicURL
                  : images.image3.image.childImageSharp?.fluid.src
              }
              id="img3"
              style={{
                width: "82%",
                height: "100%",
                display: "flex",
                y: "13",
                x: "30",
              }}
            />
          </AboutImagesWrapper>
          <AboutImagesWrapper>
            <SvgBackground
              link={
                images.image4.image.extension == "svg"
                  ? images.image4.image.publicURL
                  : images.image4.image.childImageSharp?.fluid.src
              }
              id="img4"
            />
          </AboutImagesWrapper>
          <AboutImagesWrapper>
            <SvgBackground
              link={
                images.image5.image.extension == "svg"
                  ? images.image5.image.publicURL
                  : images.image5.image.childImageSharp?.fluid.src
              }
              id="img5"
            />
          </AboutImagesWrapper>
        </AllWraped>
      </AboutImages>
    </>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  images: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image4: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image5: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Home>
        <HomeContent>
          <AboutUsContentWrap>
            <AboutPageTemplate
              contentComponent={HTMLContent}
              title={post.frontmatter.title}
              content={post.html}
              images={post.frontmatter.sideImages}
            />
          </AboutUsContentWrap>
        </HomeContent>
      </Home>
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        sideImages {
          image1 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
          image4 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
          image5 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
      }
    }
  }
`;
